import React from 'react';
import './BigPhoto.scss';

const BigPhoto = ({photo, title, description}) => {
    return (
        <div className="bigPhoto">
            <h4>{title}</h4>
            {description && <p>{description}</p>}
            <img src={require(`../../assets/img/${photo}.jpg`)} alt={title} />
        </div>
    );
};

export default BigPhoto;
