const FreePlaces = {
    clarissa: {
        2025: {
            total: 4,
            free: 0,
        },
        2026: {
            total: 4,
            free: 4,
        },
        2027: {
            total: 4,
            free: 4,
        }
    },
    sabine: {
        2025: {
            total: 5,
            free: 0,
        },
        2026: {
            total: 5,
            free: 1,
        },
        2027: {
            total: 5,
            free: 5,
        },
    },
};

export default FreePlaces;
