import React from 'react';
import StartTeaser from '../../Components/StartTeaser/StartTeaser';
import TeaserList from '../../Components/TeaserList/TeaserList';
import teasers from '../../Config/Teasers';
import setTitle from '../../Helpers/setTtitle';
import Title from "../../Components/Title/Title";

const Home = () => {
    setTitle();

    return (
        <>
            <StartTeaser />
            <div className="inner">
                <TeaserList teasers={teasers} />
                <div className="box noPadding">
                    <p>
                        Uns bedeutet es sehr viel Kinder liebevoll zu begleiten,
                        ihre Stärken zu fördern und ihnen die Zeit zu geben, die
                        sie für ihre persönlichen Entwicklungsschritte brauchen.
                    </p>

                    <Title title="Neuigkeiten" />

                    <ul className="news">
                        <li>
                            <h3>2024</h3>
                            <h4>Neue sinnesreiche Angebote:</h4>
                            <ul>
                                <li style={{marginBottom: 10}}>
                                    <h5 style={{marginBottom: 0}}>Experimentieren & Gestalten:</h5>
                                    <p>Beim Matschen und Schmieren entfalten die Kinder ihre Kreativität.</p>
                                </li>
                                <li style={{marginBottom: 10}}>
                                    <h5 style={{marginBottom: 0}}>Yoga mit Yogi:</h5>
                                    <p>Die kleine Yoga-Puppe Yogi lädt die Kinder zu sich ins Zauber-Yoga-Land ein. Die
                                    gemeinsamen kindgerechten Yoga-Übungen geben uns Energie.</p>
                                </li>
                                <li>
                                    <h5 style={{marginBottom: 0}}>Entspannen & Wahrnehmen:</h5>
                                    <p>Durch kurze Fantasiegeschichten entspannen die Kinder und nehmen die Umwelt
                                    ganz besonders wahr.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>2023</h3>
                            <h4>Sabine:</h4>
                            <ul>
                                <li>Fortbildung "Kinderpartizipation im Alltag"</li>
                                <li>Fortbildung "Naturnahes Außengelände gestalten"</li>
                                <li>Fortbildung "Sprachbeobachtung, -förderung und -dokumentation in der Kindertagespflege"</li>
                                <li>Erste-Hilfe-Kurs</li>
                            </ul>

                            <h4>Clarissa:</h4>
                            <ul>
                                <li>Fortbildung "Ernährungsbildung in der Kita"</li>
                                <li>Fortbildung "Sprachbeobachtung, -förderung und -dokumentation in der Kindertagespflege"</li>
                                <li>Fortbildung "Gute Eingewöhnung für Kind und Familie"</li>
                                <li>Fortbildung "Wenn zwei sich streiten, Konfliktlösung in der Kindertagespflege"</li>
                                <li>Erste-Hilfe-Kurs</li>
                            </ul>
                        </li>
                    </ul>

                    <cite>
                        „Wesentlich ist, dass das Kind möglichst viele Dinge
                        selbst entdeckt. Wenn wir ihm bei der Lösung aller
                        Aufgaben behilflich sind, berauben wir es gerade dessen,
                        was für seine geistige Entwicklung das Wichtigste ist.
                        <br />
                        Ein Kind, das durch selbständige Experimente etwas
                        erreicht, erwirbt ein ganz andersartiges Wissen als
                        eines, dem die Lösung fertig geboten wird.“
                        <small>– Emmi Pickler</small>
                    </cite>
                </div>
            </div>
        </>
    );
};

export default Home;
