import React from 'react';
import './StartTeaser.scss';
import Logo from '../../assets/img/EntdeckerHaeuschenLogoWhite.svg';

const StartTeaser = () => {
    return (
        <div id="startTeaser">
            <div className="logo-holder">
                <img src={Logo} alt="" />
                <h2>
                    Unsere Leidenschaft ist
                    <br /> die liebevolle Betreuung Ihrer Kinder.
                </h2>
            </div>
            <div className="startTeaser-overlay"></div>
            <img
                src={require('../../assets/img/StartTeaser.jpg')}
                alt=""
                className="background"
            />
        </div>
    );
};

export default StartTeaser;
