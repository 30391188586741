import React from 'react';
import Title from '../../Components/Title/Title';
import teasers from '../../Config/Teasers';
import Teaser from '../../Components/Teaser/Teaser';
import setTitle from '../../Helpers/setTtitle';
import { Link } from 'react-router-dom';
import PageNavigation from "../../Components/PageNavigation/PageNavigation";
import BigPhoto from "../../Components/BigPhoto/BigPhoto";

const teaser = teasers.find((t) => t.text === 'Angebote & Projekte');

const OffersProjects = () => {
    setTitle('Angebote & Projekte');

    return (
        <>
            <PageNavigation />
            <div className="box inner">
                <Title title="Angebote & Projekte" />
                <Teaser teaser={teaser} noLink />
                <h3>Sinnesreiche Angebote</h3>

                <BigPhoto photo="ExperimentierenGestalten" title="Experimentieren & Gestalten"
                          description="Beim Matschen und Schmieren entfalten die Kinder ihre Kreativität." />

                <BigPhoto photo="YogaYogi" title="Yoga mit Yogi"
                          description="Die kleine Yoga-Puppe Yogi lädt die Kinder zu sich ins Zauber-Yoga-Land ein.
                          Die gemeinsamen kindgerechten Yoga-Übungen geben uns Energie." />

                <BigPhoto photo="EntspannenWahrnehmen" title="Entspannen & Wahrnehmen"
                          description="Durch kurze Fantasiegeschichten entspannen die Kinder und nehmen die Umwelt
                          ganz besonders wahr." />

                <h3 style={{marginTop: 40}}>Über das ganze Jahr verzaubern uns viele Projekte</h3>

                <BigPhoto photo="PitschPatschSommerProjekt" title="Pitsch Patsch Sommer Projekt" />
                <BigPhoto photo="ProjektDerSinne" title="Projekt der Sinne" />
                <BigPhoto photo="RaupeNimmersattProjekt" title="Raupe Nimmersatt" />
                <BigPhoto photo="HerbstZauberProjekt" title="Herbstzauber Projekt" />

                <br /><br />
                <Link to="/kontakt" className="button">
                    Termin vereinbaren
                </Link>
            </div>
        </>
    );
};

export default OffersProjects;
