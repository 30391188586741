import React from 'react';
import './Footer.scss';
import Logo from '../../assets/img/EntdeckerHaeuschenLogoWhite.svg';
import { Link } from 'react-router-dom';
// import ChatBot from 'react-simple-chatbot';
// import { ThemeProvider } from 'styled-components';
// import FreePlaces from "../FreePlaces/FreePlaces";

/*
const chatBotTheme = {
    background: '#fff',
    headerBgColor: '#215a34',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#d7f1df',
    botFontColor: '#215a34',
    userBubbleColor: '#fff',
    userFontColor: '#215a34',
};

const chatBotInputToAction = (input) => {
    const checkInput = input.toLowerCase();
    let action = 'unknown';
    if (checkInput.includes('platz') ||
        checkInput.includes('plätze') ||
        checkInput.includes('frei')) {
        action = 'freePlaces';
    }
    if (checkInput.includes('konzept') ||
        checkInput.includes('konzeption')) {
        action = 'concept';
    }
    if (checkInput.includes('zeiten') ||
        checkInput.includes('geöffnet') ||
        checkInput.includes('öffnet') ||
        checkInput.includes('offen') ||
        checkInput.includes('bringen') ||
        checkInput.includes('holen') ||
        checkInput.includes('abholen')) {
        action = 'openingHours';
    }
    return action;
}

const chatBotSteps = [
    {
        id: 'greeting',
        message: 'Hallo! Wir sind die kleine Waldschnecke Frida und der kleine Herr Igel. Wir begrüßen dich im Entdeckerhäuschen und du kannst uns hier Fragen stellen, z.B.:',
        trigger: 'faq',
    },
    {
        id: 'faq',
        options: [
            { value: 1, label: 'Freie Plätze', trigger: 'freePlaces' },
            { value: 2, label: 'Konzeption', trigger: 'concept' },
            { value: 3, label: 'Öffnungszeiten', trigger: 'openingHours' },
            { value: 4, label: 'Andere Frage', trigger: 'userInput' },
        ],
    },
    {
        id: 'user',
        user: true,
        trigger: ({value}) => {
            return chatBotInputToAction(value);
        },
    },
    {
        id: 'userInput',
        message: 'Gib unten in das Feld einfach deine Frage ein.',
        trigger: 'user',
    },
    {
        id: 'freePlaces',
        component: <FreePlaces />,
        trigger: 'user',
    },
    {
        id: 'concept',
        component: (
            <div>
                <p className="sc-bZQynM fXzvFG rsc-ts-bubble">Gerne senden wir dir unsere Konzeption zu, nimm dazu einfach Kontakt mit uns auf:</p>
                <Link to="/kontakt" className="button mt-1">
                    Kontakt aufnehmen
                </Link>
            </div>
        ),
        trigger: 'user',
    },
    {
        id: 'openingHours',
        component: (
            <div>
                <h3>Sabine Neuburg</h3>
                <p className="info openings">
                    Öffnungszeiten:
                    <br />
                    <span>Mo.:</span> 07:45 – 15:00 Uhr
                    <br />
                    <span>Di. bis Do.:</span> 07:45 – 14:00 Uhr
                    <br />
                    <span>Fr.:</span> 07:45 – 12:00 Uhr
                </p>

                <hr />
                <h3>Clarissa Doumit</h3>
                <p className="info openings">
                    Öffnungszeiten:
                    <br />
                    <span>Mo.:</span> 07:45 – 15:00 Uhr
                    <br />
                    <span>Di. bis Do.:</span> 07:45 – 14:00 Uhr
                    <br />
                    <span>Fr.:</span> 07:45 – 12:00 Uhr
                </p>
            </div>
        ),
        trigger: 'user',
    },
    {
        id: 'unknown',
        message: 'Das haben wir leider nicht verstanden. Vielleicht findest du hier etwas:',
        trigger: 'faq',
    },
]
*/

const Footer = () => {
    return (
        <footer>
            <div className="inner">
                <img
                    src={Logo}
                    alt="EntdeckerHäuschen - Großtagespflege in Swisttal Heimerzheim"
                    className="logo"
                />

                <section>
                    Entdeckerhäuschen
                    <br />
                    Sabine Neuburg &amp; Clarissa Doumit
                    <br />
                    Kölner Straße 23 • 53913 Swisttal-Heimerzheim
                </section>

                <section>
                    <ul>
                        <li>
                            <Link to="/">Startseite</Link>
                        </li>
                        <li>
                            <Link to="/ueber-uns">Über uns</Link>
                        </li>
                        <li>
                            <Link to="/das-entdeckerhaeuschen">
                                Das Entdeckerhäuschen
                            </Link>
                        </li>
                        <li>
                            <Link to="/angebote-und-projekte">Angebote & Projekte</Link>
                        </li>
                        <li>
                            <Link to="/kontakt">Kontakt</Link>
                        </li>
                        <li>
                            <Link to="/impressum">Impressum</Link>
                        </li>
                        <li>
                            <Link to="/datenschutz">Datenschutz</Link>
                        </li>
                    </ul>
                </section>

                <section>
                    <p>
                        <small>Diese Seite verwendet keine Cookies.</small>
                    </p>
                    &copy; {new Date().getFullYear()} Entdeckerhäuschen
                </section>

                {/*
                <ThemeProvider theme={chatBotTheme}>
                    <ChatBot
                        botAvatar={require(`../../assets/img/ChatBot.jpg`)}
                        floating={true}
                        placeholder={'Gib eine Frage ein'}
                        steps={chatBotSteps}
                    />
                </ThemeProvider>
                */}
            </div>
        </footer>
    );
};

export default Footer;
