import React from 'react';
import Title from '../../Components/Title/Title';
import teasers from '../../Config/Teasers';
import Teaser from '../../Components/Teaser/Teaser';
import setTitle from '../../Helpers/setTtitle';
import { Link } from 'react-router-dom';
import FreePlaces from '../../Components/FreePlaces/FreePlaces';
import PageNavigation from "../../Components/PageNavigation/PageNavigation";
import Photos from "../../Components/Photos/Photos";

const teaser = teasers.find((t) => t.text === 'Das Entdecker&shy;häuschen');

const TheEntdeckerHaeuschen = () => {
    setTitle('Das Entdeckerhäuschen');

    const [iframeHeight, setIframeHeight] = React.useState(200);

    const updateIframeHeight = () => {
        const box = document.querySelector('.box');
        const styles = getComputedStyle(box);
        const width = parseInt(styles.width.replace('px', '')) - parseInt(styles.paddingRight.replace('px', ''))
        const height = width / 16 * 9 - 12;
        setIframeHeight(height);
    }

    React.useEffect(() => {
        window.addEventListener("resize", (event) => {
            updateIframeHeight();
        });
        updateIframeHeight();
    }, [])

    return (
        <>
            <PageNavigation />
            <div className="box inner">
                <Title title="Das Entdeckerhäuschen" />
                <Teaser teaser={teaser} noLink />

                <div className="info mb-4">
                    <p>
                        Sie möchten gerne unser pädagogisches Konzept lesen?
                        Nehmen Sie Kontakt mit uns auf und wir senden es Ihnen
                        gerne zu!
                    </p>
                    <Link to="/kontakt" className="button">
                        Kontakt
                    </Link>
                </div>

                <iframe title="Rundgang durch das Entdeckerhäuschen" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" width="100%" height={`${iframeHeight}px`} allowFullScreen style={{marginBottom: 16}}
                        src="https://www.youtube.com/embed/lYhb0vdYPqk?origin=https://entdecker-haeuschen.de&amp;modestbranding=1&amp;showinfo=0&amp;rel=0">
                </iframe>

                <cite>
                    <strong>„Hilf mir es selbst zu tun!“</strong>
                    <small>– Maria Montessori</small>
                </cite>

                <p>
                    Die Betreuung findet im zweiten Obergeschoss des „Alten
                    Klosters“ in der Kölner Straße statt. Alle Familien unserer
                    Tageskinder bekommen nach schriftlicher Bestätigung einen
                    Chip, um den Aufzug bedienen zu können.
                </p>

                <div className="highlight mb-4">
                    <FreePlaces />
                    <Link to="/kontakt" className="button mt-1">
                        Termin vereinbaren
                    </Link>
                </div>

                <h3>Eingangsbereich</h3>

                <p>
                    Der Eingangsbereich / Flur ist so hergerichtet, dass sich
                    alle willkommen fühlen. Im Flur befinden sich die kleine
                    Waldschnecke Frida und der kleine Herr Igel und es gibt
                    einen Infobereich mit allen wichtigen Informationen
                    (Essensplan, Urlaubsplanung, Wochenrückblick, usw.) für die
                    Eltern.
                </p>

                <p>
                    Der Garderobenplatz ist mit Fotos gekennzeichnet – dadurch
                    erfahren die Kinder Vertrautheit und Orientierung.
                </p>

                <Photos location="eingangsbereich" />

                <h3>Waschraum „Kleiner Wasserfall“</h3>

                <p>
                    Der Waschraum ist mit einem kindgerechten Wickelbereich
                    ausgestattet. Viele sinnliche Erfahrungen können die Kinder
                    in diesem Raum sammeln.
                </p>

                <Photos location="waschraum" />

                <h3>Küche „Lecker-Schmecker-Platz“</h3>

                <p>
                    Unsere große, offene Küche bietet einerseits den Kindern die
                    Möglichkeit mit uns gemeinsamen zu schnibbeln, backen und
                    kochen und andererseits den Platz sich im Feuerwehr-Bällebad
                    auszutoben. Für erste eigene Kocherfahrungen gibt es eine
                    Spielküche.
                </p>

                <Photos location="kueche" />

                <h3>Schlafraum „Stille Zauberhöhle“</h3>

                <p>
                    Dieser Raum bietet den Kindern eine angenehme, ruhige
                    Atmosphäre. Er lädt zum Ausruhen und Erholen ein. Jedes Kind
                    hat einen eigenen Kuschelbereich.
                </p>

                <Photos location="schlafraum" />

                <h3>Betreuungsraum „Entdecker-Wäldchen“</h3>

                <p>
                    Dieser Raum ist so ausgestattet, dass den Kindern zahlreiche
                    Spiel- und Erfahrungsmöglichkeiten geboten werden. So gibt
                    es eine Kuschelecke und einen Rollenspiele-Bereich. Des
                    Weiteren gibt es einen Bau- und Kreativbereich.
                </p>

                <Photos location="betreuungsraum" />

                <h3>Außengelände „Abenteuer-Garten“</h3>

                <p>
                    Unser Garten besteht aus einer Matsch-Küche, Sandkasten und
                    Rutsche. Einen Teil nutzen wir als selbstangebauten Kräuter-
                    und Gemüsegarten. In der Nähe befindet sich ein schöner
                    Spielplatz, der sogar den Kleinsten ein spannendes
                    Klettergerüst bietet.
                </p>

                <Photos location="aussengelaende" />

                <Link to="/kontakt" className="button">
                    Termin vereinbaren
                </Link>
            </div>

            <div className="box inner">
                <Title title="Tagesablauf" />
                <h3>Ein Tag im Entdeckerhäuschen</h3>

                <p>
                    Im Tagesablauf werden die verschiedenen Rituale, die den
                    Kindern enorme Sicherheit und Orientierung geben und deshalb
                    von großer Bedeutung sind, umgesetzt.
                </p>

                <p>
                    Eine Mischung aus Zeiten des „freien Spielens“ und gezielten
                    pädagogischen Angeboten (z. B. Morgenkreis) spielen hierbei
                    eine endscheidende Rolle.
                </p>

                <h4>Ankommen</h4>

                <p>
                    Die Kinder und Eltern sollen morgens schon zufrieden in den
                    Tag starten. Aus diesem Grund ist es uns wichtig uns beim
                    Bringen und Abholen Zeit für Tür- und Angelgespräche zu
                    nehmen.
                </p>

                <p>
                    Ein Ritual ist die Begrüßung der Handpuppen kleine
                    Waldschnecke Frida aus ihrem Körbchen und dem kleinen Igel
                    "Herr Igel" an der Haustüre.
                </p>

                <h4>Freispiel</h4>

                <p>
                    Früh morgens, wenn die Kinder nach und nach bei uns
                    ankommen, findet hauptsächlich Freispiel statt.
                </p>

                <h4>Auf Entdeckung</h4>

                <p>
                    Wir gehen jeden Tag nach draußen, denn nichts bietet den
                    Kindern mehr sinnliche Eindrücke als die Natur. Wir gehen in
                    unseren Garten, den Wald und auf Felder, Wiesen und
                    Spielplätze.
                </p>

                <p>
                    Im Dorflädchen machen wir kleine Besorgungen, z. B.: Obst
                    oder Gemüse.
                </p>

                <p>Wir werden gelegentlich draußen gemeinsam frühstücken.</p>

                <h4>Frühstück</h4>

                <p>
                    Um 9:00 Uhr sind alle Kinder da und wir beginnen mit dem
                    gemeinsamen Frühstück.
                    <br />
                    Uns ist es wichtig den Kindern das Gefühl der
                    Gruppenzugehörigkeit zu vermitteln und einen gemeinsamen
                    Start in den Tag zu ermöglichen. Danach wird gemeinsam
                    abgeräumt und wir gehen Hände und Mund waschen.
                </p>

                <h4>Morgenkreis</h4>

                <p>
                    Nun beginnen wir mit unserem Begrüßungslied „Hallo, schön
                    dass du da bist“ bei unserem gemeinsamen Morgenkreis.
                    Bewegungsspiele und gemeinsame Lieder und Fingerspiele
                    werden gesungen.
                </p>

                <h4>Freispiel / Angebote</h4>

                <p>
                    In der Zeit des freien Spiels bieten wir gezielte Aktionen
                    und Angebote mit einigen oder allen Kindern an.
                    <br />
                    Das Kind trifft dabei selbst die Entscheidung ob es daran
                    teilnimmt.
                    <br />
                    Die Angebote werden situationsorientiert am
                    Entwicklungsstand und Tagesbefinden der Kinder ausgewählt
                    (Sinnes- und Bewegungs-Parcour, Kreativangebot oder
                    Matschangebot).
                </p>

                <h4>Wickelzeit</h4>

                <h4>Mittagessen</h4>

                <p>
                    Das Mittagessen wird mit den Kindern zubereitet.
                    <br />
                    Nach unserem gemeinsamen „Guten Appetit“-Spruch von „Die
                    kleine Raupe Nimmersatt“ beginnen wir gemeinsam mit dem
                    Essen. Die Kinder werden beimTischdecken mit einbezogen.
                    <br />
                    Wir unterstützen sie sich das Essen selbst zu nehmen. Sie
                    entscheiden selbst, was und wie viel sie essen möchten.
                </p>

                <p>
                    Nach dem gemeinsamen Essen werden die Hände gewaschen und
                    die Ruhezeit beginnt.
                </p>

                <h4>Schlafen / Ruhen</h4>

                <p>
                    Nach einem Vormittag des Spielens und Lernens ist Schlafen,
                    Ausruhen und Entspannen wichtig.
                </p>

                <p>
                    Wir begleiten die Kinder in den Schlaf. Durch
                    Einschlafrituale, Schlaflieder, einer Spieluhr, vertraute
                    Gegenstände (Kuscheltier, Schnuller, Schmusetuch) können die
                    Kinder sich entspannen und wohlfühlen.
                </p>

                <h4>Wickelzeit</h4>

                <h4>Nachmittag-Snack</h4>

                <p>
                    Nach dem Ausruhen bereiten wir gemeinsam mit den Kindern den
                    Snack zu und wir essen Obst oder Rohkost.
                </p>

                <h4>Freispiel / Abholung</h4>

                <p>
                    Zwischen 14:00 und 15:00 Uhr (abhängig vom Wochentag und der
                    betreuenden Person, siehe Öffnungszeiten unter{' '}
                    <Link to={'/ueber-uns'}>Über uns</Link>) werden die Kinder
                    abgeholt. Ein kurzer Austausch von den Erlebnissen der
                    Kinder findet statt.
                </p>

                <h3>Versorgung / Pflege der Kinder</h3>

                <h4>Ernährung</h4>

                <p>
                    Essen ist eine sinnliche Erfahrung. Die Kinder können das
                    Essen riechen, sehen, schmecken und fühlen. Sie bringen
                    jeden Tag ihr Frühstück mit, einmal pro Woche bereiten wir
                    gemeinsam gesundes Frühstück zu.
                </p>

                <p>
                    Durch die gemeinsamen Mahlzeiten erleben die Kinder Struktur
                    und familiäre Atmosphäre. Das Essen ist ein Ritual, bei dem
                    eine kommunikative Situation entsteht. Bei der
                    Essenssituation legen wir Wert darauf, dass die Kinder mehr
                    und mehr selbständig essen lernen.
                </p>

                <p>
                    Die Kinder dürfen uns gelegentlich bei der Zubereitung der
                    Speisen und beim Tischdecken helfen. Sie bringen ihr
                    Frühstück von zu Hause mit. Spezielle Babynahrung wird
                    ebenfalls von den Eltern mitgebracht. Getränke wie Wasser
                    und ungesüßten Tee erhalten die Kinder von uns.
                </p>

                <p>
                    Gesunde, ausgewogene und kindgerechte Mahlzeiten sind uns
                    sehr wichtig. Wir achten auf Frische und Herkunft der
                    Lebensmittel.
                    <br />
                    Der Essensplan wird für die Kinder bildlich dargestellt.
                </p>

                <h4>Pflege</h4>

                <p>
                    Die Pflege ist eine ganz besondere sensible Angelegenheit,
                    weil sie die Intimsphäre des Kindes betrifft.
                </p>

                <h4>Wickeln</h4>

                <p>
                    Eine bezugsvolle Pflege angelehnt an Emmi Pikler ist uns
                    sehr wichtig. Dies bedeutet für uns einen feinfühligen,
                    achtsamen und respektvollen Umgang mit dem Kind beim
                    Wickeln.
                    <br />
                    Wir begleiten unser Handeln sprachlich und nehmen uns viel
                    Zeit.
                </p>

                <h4>Sauberkeitsentwicklung</h4>

                <p>
                    Das Kind bestimmt den Zeitpunkt, wann es auf die Toilette
                    gehen möchte.
                    <br />
                    Um diese Entwicklung bestmöglich zu unterstützen, wird von
                    uns eine intensive Beobachtung, Sensibilität und Achtsamkeit
                    verlangt. Dazu arbeiten wir eng mit den Eltern zusammen, um
                    einen einheitlichen Ablauf zu gewährleisten und dem Kind
                    Sicherheit zu vermitteln. Ganz ohne Stress und Druck soll
                    dies für das Kind erlebt werden.
                </p>

                <p>
                    Wir bestärken das Kind positiv bei dieser wichtigen Phase.
                    Dadurch wird das Selbstwertgefühl des Kindes bestärkt.
                </p>

                <Link to="/kontakt" className="button">
                    Termin vereinbaren
                </Link>
            </div>
        </>
    );
};

export default TheEntdeckerHaeuschen;
