import React from 'react';
import './Teaser.scss';
import { Link } from 'react-router-dom';

const Teaser = (props) => {
    const { teaser, noLink, tiny, inNavigation, isActive } = props;

    return (
        <>
            {!noLink ? (
                <Link to={teaser.url} className={`teaser${isActive ? ' active' : ''}`}>
                    <img
                        src={require(`../../assets/img/${teaser.img}`)}
                        alt=""
                        style={{width: tiny ? '50px' : '100%' }}
                    />
                    <strong className={inNavigation ? 'inNavigation' : ''} dangerouslySetInnerHTML={{__html: teaser.text }} />
                </Link>
            ) : (
                <div className="teaser noLink">
                    <img
                        src={require(`../../assets/img/${teaser.img}`)}
                        alt=""
                        style={{width: tiny ? '50px' : '100%' }}
                    />
                </div>
            )}
        </>
    );
};

export default Teaser;
