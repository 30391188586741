import React from 'react';
import PageNavigation from '../../Components/PageNavigation/PageNavigation';
import teasers from '../../Config/Teasers';
import Teaser from '../../Components/Teaser/Teaser';
import setTitle from '../../Helpers/setTtitle';
import {Link} from 'react-router-dom';
import FreePlaces from '../../Components/FreePlaces/FreePlaces';
import Title from "../../Components/Title/Title";

const teaser = teasers.find((t) => t.text === 'Über uns');
const teaserSabine = { img: 'Sabine.jpg' };
const teaserClarissa = { img: 'Clarissa.jpg' };

const AboutUs = () => {
    setTitle('Über uns');

    return (
        <>
            <PageNavigation />
            <div className="box inner">
                <Title title="Über uns" />
                <Teaser teaser={teaser} noLink />
                <p>
                    Wir, Sabine und Clarissa, sind langjährige Freundinnen und
                    konnten unseren gemeinsamer Traum von einer Großtagespflege
                    verwirklichen.
                    <br />
                    Wir begleiten die Kinder beim Erforschen und Erkunden.
                </p>
                <p className="highlight">
                    Wir teilen beide die Begeisterung und Leidenschaft: die
                    liebevolle Betreuung Ihrer Kinder und alles Rund um Natur
                    und Bewegung.
                </p>
                <p>
                    Durch die Zusammenarbeit findet ein gemeinsamer Austausch
                    und Reflexion statt. Auch die Kinder profitieren davon zwei
                    Bezugspersonen um sich zu haben. Das Tagesgeschehen lässt
                    sich bedürfnisorientiert gestalten. In vielen Punkten ist es
                    für uns und die Kinder eine Bereicherung, dass wir gemeinsam
                    die Großtagespflege leiten.
                </p>

                <FreePlaces />
                <Link to="/kontakt" className="button mt-1">
                    Termin vereinbaren
                </Link>
            </div>

            <div className="box inner">
                <Teaser teaser={teaserSabine} noLink />
                <h3 id="sabine">Sabine Neuburg</h3>
                <p className="info openings">
                    Öffnungszeiten:
                    <br />
                    <span>Mo.:</span> 07:45 – 15:00 Uhr
                    <br />
                    <span>Di. bis Do.:</span> 07:45 – 14:00 Uhr
                    <br />
                    <span>Fr.:</span> 07:45 – 12:00 Uhr
                </p>

                <p>
                    Ich wurde im Oktober 1983 in Euskirchen geboren. Ich bin mit
                    meinem Mann Sammy Neuburg seit Oktober 2011 verheiratet. Zu
                    uns gehören unser Sohn Ben, der im Juli 2016 geboren wurde
                    und unsere Tochter Lina, welche im Februar 2019 auf die Welt
                    kam.
                </p>

                <p>
                    Ich habe nach 2-jähriger Ausbildung im Sommer 2003 meine
                    Prüfung zur Kinderpflegerin absolviert. Im Sommer 2010 legte
                    ich die Prüfung zur staatlich anerkannten Erzieherin
                    erfolgreich ab.
                </p>

                <p>
                    Seitdem war ich  in verschiedenen Gruppenformen tätig. 10
                    Jahre habe ich als Gruppenleitung die Krippengruppe, in der
                    Kinder im Alter von 6 Monaten bis 3 Jahren sind, betreut.
                </p>

                <p>Ich habe zahlreiche Fortbildungen absolviert:</p>
                <ul>
                    <li>Bewegungsfachkraft</li>
                    <li>Fachkraft für Kinder unter 3 Jahren</li>
                    <li>Kinderschutzfachkraft § 8a</li>
                    <li>Datenschutzbeauftragte</li>
                </ul>

                <p>Haus der kleinen Forscher:</p>

                <ul>
                    <li>
                        Schulung zum Thema Forschendes und Entdeckendes Lernen
                    </li>
                </ul>

                <p>Psychomotorikzentrum Bonn:</p>
                <ul>
                    <li>Bewegung</li>
                    <li>Sprache</li>
                    <li>Wahrnehmung</li>
                    <li>
                        Weiterbildung im Bereich Beobachten und Dokumentieren
                    </li>
                </ul>

                <p>
                    Im Frühjahr 2021 beendete ich einen
                    Kindertagespflege-Zertifizierungskurs.
                </p>

                <p>
                    Mit der Gründung des Entdeckerhäuschens geht für mich ein
                    Herzenswunsch in Erfüllung mich selbständig zu machen und
                    Familie und Beruf bestens zu vereinen.
                </p>

                <p>
                    Mein Angebot zur Kindertagespflege richtet sich an Eltern,
                    die meine Arbeit als notwendige und sinnvolle Ergänzung für
                    die Entwicklung ihrer Kinder sehen.
                </p>

                <p>
                    <Link to="/kontakt/sabine" className="button">
                        Sabine kontaktieren
                    </Link>
                </p>
            </div>

            <div className="box inner">
                <Teaser teaser={teaserClarissa} noLink />
                <h3 id="clarissa">Clarissa Doumit</h3>
                <p className="info openings">
                    Öffnungszeiten:
                    <br />
                    <span>Mo.:</span> 07:45 – 15:00 Uhr
                    <br />
                    <span>Di. bis Do.:</span> 07:45 – 14:00 Uhr
                    <br />
                    <span>Fr.:</span> 07:45 – 12:00 Uhr
                </p>

                <p>
                    Mein Name ist Clarissa Doumit, ich bin im Oktober 1985
                    geboren und bin mit meinem Mann Remon seit 2016 verheiratet.
                    Unsere Tochter Sofia kam im Januar 2018 zur Welt.
                </p>
                <p>
                    Im Mai 2009 habe ich meine Ausbildung zur Medizinischen
                    Fachangestellten erfolgreich in einer Praxis für Kinder und
                    Jugendmedizin abgeschlossen.
                </p>
                <p>Zu meinem Aufgabenbereich zählten:</p>
                <ul>
                    <li>Assistenz bei ärztlicher Diagnostik und Therapie</li>
                    <li>Seh- sowie Hörtestungen</li>
                    <li>Entwicklungstests</li>
                    <li>Vorbereitung der Vorsorgeuntersuchungen</li>
                </ul>
                <p>
                    Ab September 2009 bis Februar 2015 unterstützte ich als
                    Medizinische Fachangestellte eine Kinderarztpraxis für
                    Kardiologie und Allergologie.
                </p>
                <p>Zu meinem erweiterten Aufgabenbereich zählten außerdem:</p>
                <ul>
                    <li>Sprachtestungen</li>
                    <li>Intelligenztestungen</li>
                    <li>Kardiologische Untersuchungen</li>
                    <li>Allergologische Untersuchungen</li>
                </ul>
                <p>
                    Durch die kardiologischen und allergologischen
                    Untersuchungen habe ich schon früh gelernt, auch in
                    schwierigen Situationen einfühlsam und feinfühlig auf die
                    kleinsten Kinder einzugehen und eine vertrauensvolle Basis
                    aufzubauen.
                </p>
                <p>
                    2013 habe ich eine Fortbildung zur Praxisassistentin für
                    Asthma und Allergien bei Kleinkindern und Jugendlichen
                    erfolgreich abgeschlossen.
                </p>
                <p>
                    Von März 2015 bis zum Sommer 2021 arbeitete ich am
                    Universitätsklinikum Bonn in der Schwangerenambulanz des
                    Eltern-Kind-Zentrums.
                </p>
                <p>Zu meinem Aufgabenbereich zählten:</p>
                <ul>
                    <li>die Betreuung von Schwangeren und Risikoschwangerschaften</li>
                    <li>
                        Notfallsituationen erkennen und entsprechendes Handeln
                    </li>
                    <li>Krankenhausabrechnung</li>
                </ul>
                <p>Im März 2021 beendete ich den Kindertagespflege-Zertifizierungskurs.</p>
                <p>Mit der Entstehung des Entdeckerhäuschens geht für mich ein Lebenstraum in Erfüllung.</p>
                <p>Es bereitet mir jeden Tag aufs Neue Freude, unsere kleinen Entdecker persönlich in ihrer Entwicklung zu begleiten und in ihrer Einzigartigkeit zu unterstützen.</p>

                <p>
                    <Link to="/kontakt/clarissa" className="button">
                        Clarissa kontaktieren
                    </Link>
                </p>
            </div>
        </>
    );
};

export default AboutUs;
