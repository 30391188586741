import React from 'react';
import './Photos.scss';

const Photos = ({location}) => {
    const [showInFullscreen, setShowInFullscreen] = React.useState(null);
    return (
        <div className="photos">
            <ul>
                {[1,2,3,4].map(i => (
                    <li key={i} onClick={() => {
                        setShowInFullscreen(`${location}_${i}`);
                    }}>
                        <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 131.25" x="0px" y="0px"><path d="M78.93,68.48,98.76,88.27a4.29,4.29,0,0,1,0,6.07l-4.4,4.41a4.29,4.29,0,0,1-6.07,0L68.45,79a2.14,2.14,0,0,1,.19-3.2,44,44,0,0,0,3.76-3.34,43.43,43.43,0,0,0,3.33-3.74A2.14,2.14,0,0,1,78.93,68.48ZM16,69.06a37.52,37.52,0,1,1,26.54,11A37.53,37.53,0,0,1,16,69.06Zm1.19-26.55a4.29,4.29,0,0,0,4.29,4.3H36.08A2.15,2.15,0,0,1,38.23,49V63.57a4.3,4.3,0,0,0,4.29,4.3h0a4.31,4.31,0,0,0,4.3-4.3V49A2.15,2.15,0,0,1,49,46.81H63.58a4.3,4.3,0,1,0,0-8.59H49a2.15,2.15,0,0,1-2.15-2.15V21.45a4.3,4.3,0,0,0-4.3-4.29h0a4.29,4.29,0,0,0-4.29,4.29V36.07a2.15,2.15,0,0,1-2.15,2.15H21.46A4.29,4.29,0,0,0,17.17,42.51Z"/></svg>
                            Größer</span>
                        <img src={require(`../../assets/img/${location}_${i}.jpg`)} alt={location} />
                    </li>
                ))}
            </ul>
            {showInFullscreen && (
                <div className="modal" onClick={() => {
                    setShowInFullscreen(null);
                }}>
                    <div className="modalInner">
                        <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 519 648.75" x="0px"  y="0px"><g><path d="M16 425l165 -166 -165 -165c-21,-21 -21,-56 0,-78 22,-21 57,-21 78,0l165 165 166 -165c21,-21 56,-21 78,0 21,22 21,57 0,78l-166 165 166 166c21,21 21,56 0,78 -22,21 -57,21 -78,0l-166 -166 -165 166c-21,21 -56,21 -78,0 -21,-22 -21,-57 0,-78z"/></g></svg>
                            Schließen</span>
                        <img src={require(`../../assets/img/${showInFullscreen}.jpg`)} alt={showInFullscreen} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Photos;
