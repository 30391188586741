import React from 'react';
import Title from '../../Components/Title/Title';
import setTitle from '../../Helpers/setTtitle';
import PageNavigation from "../../Components/PageNavigation/PageNavigation";

const Imprint = () => {
    setTitle('Impressum');

    return (
        <>
            <PageNavigation />
            <div className="box inner noPadding">
                <Title title="Impressum" />
                <h2>Angaben gemäß § 5 TMG</h2>
                <p>
                    Großtagespflegestelle Entdeckerhäuschen
                    <br />
                    Sabine Neuburg & Clarissa Doumit
                    <br />
                    Kölner Straße 23
                    <br />
                    53913 Swisttal-Heimerzheim
                </p>
                <h3>Kontakt</h3>
                <p>
                    Telefon: +49 157 31141631 / +49 176 80570908
                    <br />
                    E-Mail:{' '}
                    <a href="mailto:team@entdecker.haus">team@entdecker.haus</a>
                </p>
                <p>
                    <small>
                        Quelle:{' '}
                        <a href="https://www.e-recht24.de/impressum-generator.html">
                            https://www.e-recht24.de/impressum-generator.html
                        </a>
                    </small>
                </p>
                <h3>Bild-Quellen</h3>
                <a
                    href="https://unsplash.com/photos/pMhCDomst7U"
                    target="_blank"
                    rel="noreferrer"
                >
                    Heidi Lumb
                </a>
                ,{' '}
                <a
                    href="https://unsplash.com/photos/obKbq4Z3cuA"
                    target="_blank"
                    rel="noreferrer"
                >
                    Scott Webb
                </a>
                ,{' '}
                <a
                    href="https://unsplash.com/photos/oT1_Vtb2NJU"
                    target="_blank"
                    rel="noreferrer"
                >
                    Markus Spiske
                </a>
                ,{' '}
                <a
                    href="https://unsplash.com/photos/qJVluHTPx7U"
                    target="_blank"
                    rel="noreferrer"
                >
                    Janko Ferlič
                </a>
                ,{' '}
                <a
                    href="https://unsplash.com/photos/h7nJKcNylZ0"
                    target="_blank"
                    rel="noreferrer"
                >
                    Omid Armin
                </a>
            </div>
        </>
    );
};

export default Imprint;
