import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Home from '../Pages/Home/Home';
import AboutUs from '../Pages/AboutUs/AboutUs';
import TheEntdeckerHaeuschen from '../Pages/TheEntdeckerHaeuschen/TheEntdeckerHaeuschen';
import OffersProjects from '../Pages/OffersProjects/OffersProjects';
import Contact from '../Pages/Contact/Contact';
import Imprint from '../Pages/Imprint/Imprint';
import DataPrivacy from '../Pages/DataPrivacy/DataPrivacy';
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ueber-uns" element={<AboutUs />} />
                <Route
                    path="/das-entdeckerhaeuschen"
                    element={<TheEntdeckerHaeuschen />}
                />
                <Route path="/tagesablauf" element={<TheEntdeckerHaeuschen />} />s
                <Route path="/angebote-und-projekte" element={<OffersProjects />} />s
                <Route path="/kontakt">
                    <Route path=":recipient" element={<Contact />} />
                    <Route path="" element={<Contact />} />
                </Route>
                <Route path="/impressum" element={<Imprint />} />
                <Route path="/datenschutz" element={<DataPrivacy />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
