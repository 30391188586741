import React from 'react';
import './Title.scss';
import Logo from '../../assets/img/EntdeckerHaeuschenLogoGreen.svg';

const Title = (props) => {
    const { title } = props;

    return (
        <>
            <div className="title">
                <h1>
                    <span className="logoHolder">
                        <img
                            src={Logo}
                            alt="EntdeckerHäuschen - Großtagespflegestelle in Swisttal Heimerzheim"
                            className="logo"
                        />
                    </span>
                    {title}
                </h1>
            </div>
        </>
    );
};

export default Title;
