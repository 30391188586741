import React, { useState } from 'react';
import Title from '../../Components/Title/Title';
import setTitle from '../../Helpers/setTtitle';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import './Contact.scss';
import Teaser from '../../Components/Teaser/Teaser';
import { useParams } from 'react-router-dom';
import PageNavigation from "../../Components/PageNavigation/PageNavigation";

const teaserSabine = { img: 'Sabine.jpg' };
const teaserClarissa = { img: 'Clarissa.jpg' };
const teaserAll = { img: 'SabineClarissa.jpg' };

const Contact = () => {
    setTitle('Kontakt');

    const { recipient: recipientFromUrl } = useParams();

    const [formSent, setFormSent] = useState(false);
    const [formError, setFormError] = useState(null);
    const [sending, setSending] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        setFormError(null);
        setSending(true);
        axios
            .post('https://entdecker-haeuschen.de/_mail/', { data })
            .then((response) => {
                if (response.data.success === true) {
                    setFormSent(true);
                } else if (response.data.status === 'fail') {
                    setFormError('error');
                }
                setSending(false);
            })
            .catch((e) => {
                setSending(false);
                setFormError(
                    'Die Nachricht konnte leider nicht versandt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
                );
            });
    };

    let selectedRecipient = recipientFromUrl || 'all';

    let teaser;
    let recipientChanged = watch('recipient');
    if (!recipientChanged) {
        recipientChanged = selectedRecipient;
    }
    switch (recipientChanged) {
        case 'sabine':
            teaser = teaserSabine;
            break;
        case 'clarissa':
            teaser = teaserClarissa;
            break;
        default:
            teaser = teaserAll;
    }

    return (
        <>
            <PageNavigation />
            <div className="box inner">
                <Title title="Kontakt" />
                <p>
                    Nehmen Sie gerne Kontakt mit uns auf, wenn Sie
                    weiterführende Informationen wünschen oder einen
                    Kennenlern-Termin vereinbaren möchten.
                </p>
                {formError && <p className="error">{formError}</p>}
                {!formSent ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset>
                            <label>Empfänger:</label>
                            <select
                                {...register('recipient')}
                                defaultValue={selectedRecipient}
                            >
                                <option value="all">
                                    Sabine &amp; Clarissa
                                </option>
                                <option value="sabine">Sabine</option>
                                <option value="clarissa">Clarissa</option>
                            </select>
                            <div className="recipientHolder">
                                <Teaser teaser={teaser} noLink />
                            </div>
                        </fieldset>

                        <fieldset>
                            <label>Bedarf ab Jahr:</label>
                            <select {...register('year', { required: true })}>
                                <option value="">Bitte wählen</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                            </select>
                            {errors.year && (
                                <div className="error">
                                    Bitte geben Sie ein Jahr an.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>Buchungszeiten:</label>
                            <select
                                {...register('bookingTimes', {
                                    required: true,
                                })}
                            >
                                <option value="">Bitte wählen</option>
                                <option value="30 Stunden">30 Stunden</option>
                                <option value="35 Stunden">35 Stunden</option>
                            </select>
                            {errors.bookingTimes && (
                                <div className="error">
                                    Bitte geben Sie die Buchungszeiten an.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>Geschlecht des Kindes:</label>
                            <select {...register('gender', { required: true })}>
                                <option value="">Bitte wählen</option>
                                <option value="weiblich">weiblich</option>
                                <option value="männlich">männlich</option>
                                <option value="noch unbekannt">
                                    noch unbekannt
                                </option>
                            </select>
                            {errors.gender && (
                                <div className="error">
                                    Bitte geben Sie das Geschlecht des Kindes
                                    an.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>Geburtstag des Kindes:</label>
                            <input
                                {...register('birthdate', { required: true })}
                                type="date"
                            />
                            {errors.birthdate && (
                                <div className="error">
                                    Bitte geben Sie den Geburtstag des Kindes
                                    an.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>Name:</label>
                            <input
                                {...register('name', { required: true })}
                                type="text"
                            />
                            {errors.name && (
                                <div className="error">
                                    Bitte geben Sie Ihren Namen an.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>E-Mail-Adresse:</label>
                            <input
                                {...register('email', { required: true })}
                                type="email"
                            />
                            {errors.name && (
                                <div className="error">
                                    Bitte geben Sie Ihre E-Mail-Adresse an.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>Telefonnummer:</label>
                            <input {...register('phone')} type="text" />
                        </fieldset>

                        <fieldset>
                            <label>Wohnort:</label>
                            <input {...register('city')} type="text" />
                        </fieldset>

                        <fieldset>
                            <label>Nachricht:</label>
                            <textarea
                                {...register('message', { required: true })}
                            ></textarea>
                            {errors.name && (
                                <div className="error">
                                    Bitte schreiben Sie uns eine Nachricht.
                                </div>
                            )}
                        </fieldset>

                        <fieldset>
                            <label>
                                <input
                                    type="checkbox"
                                    {...register('concept')}
                                ></input>
                                Ich bitte um Zusendung des pädagogischen
                                Konzeptes.
                            </label>
                        </fieldset>

                        <input
                            type="submit"
                            className="button mt-2"
                            value="Abschicken"
                            disabled={sending}
                        />
                    </form>
                ) : (
                    <p className="highlight">
                        Vielen Dank für Ihre Nachricht. Wir werden uns
                        schnellsmöglich bei Ihnen melden!
                    </p>
                )}
            </div>
        </>
    );
};

export default Contact;
