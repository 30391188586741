import React from 'react';
import './BurgerMenu.scss';
import { Link } from 'react-router-dom';

const BurgerMenu = () => {
    const closeMenu = () => {
        document.querySelector('#navi-toggle').checked = false;
    };

    return (
        <div className="menu">
            <input type="checkbox" id="navi-toggle" />
            <label htmlFor="navi-toggle" className="naviToggle">
                <span className="icon"></span>
            </label>
            <div className="background"></div>

            <nav>
                <ul>
                    <li>
                        <Link to="/" onClick={closeMenu}>
                            Startseite
                        </Link>
                    </li>
                    <li>
                        <Link to="/ueber-uns" onClick={closeMenu}>
                            Über uns
                        </Link>
                    </li>
                    <li>
                        <Link to="/das-entdeckerhaeuschen" onClick={closeMenu}>
                            Das Entdeckerhäuschen
                        </Link>
                    </li>
                    <li>
                        <Link to="/angebote-und-projekte" onClick={closeMenu}>
                            Angebote & Projekte
                        </Link>
                    </li>
                    <li>
                        <Link to="/kontakt" onClick={closeMenu}>
                            Kontakt
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default BurgerMenu;
