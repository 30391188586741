import React, { useState } from 'react';
import FreePlacesConfig from '../../Config/FreePlaces';
import './FreePlaces.scss';

const FreePlaces = (props) => {
    const { buttonColor = '' } = props;
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    const freePlaces = {};

    Object.keys(FreePlacesConfig).forEach((person) => {
        Object.keys(FreePlacesConfig[person]).forEach((year) => {
            if (!freePlaces[year]) {
                freePlaces[year] = {
                    total: 0,
                    free: 0,
                };
            }
            freePlaces[year].total += FreePlacesConfig[person][year].total;
            freePlaces[year].free += FreePlacesConfig[person][year].free;
        });
    });

    return (
        <div className="freePlaces buttonHolder">
            <div className={`button ${buttonColor}`} onClick={toggleShow}>
                Freie Plätze anzeigen{' '}
                <svg x="0px" y="0px" viewBox="0 0 318.7 122">
                    <path d="M159.4,117.9L318.7,2.1H0L159.4,117.9z" />
                </svg>
            </div>
            {show && (
                <div className="freePlacesContent">
                    <ul>
                        {Object.keys(freePlaces).map((year) => (
                            <li key={year}>
                                <strong>{year}:</strong>
                                <em>
                                    {freePlaces[year].free === 0
                                        ? 'keine freien Plätze'
                                        : `${freePlaces[year].free} / ${freePlaces[year].total} freie Plätze`}
                                </em>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {/*
            <div className={"actualFreePlaces"} onClick={toggleShow}>
                Noch ein freier Platz für 2024!
            </div>
            */}
        </div>
    );
};

export default FreePlaces;
