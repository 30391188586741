const teasers = [
    {
        url: '/ueber-uns',
        text: 'Über uns',
        img: 'SabineClarissa.jpg',
    },
    {
        url: '/das-entdeckerhaeuschen',
        text: 'Das Entdecker&shy;häuschen',
        img: 'DasEntdeckerhaeschen.jpg',
    },
    {
        url: '/angebote-und-projekte',
        text: 'Angebote & Projekte',
        img: 'AngeboteProjekte.jpg',
    },
    {
        url: '/kontakt',
        text: 'Kontakt',
        img: 'Kontakt.jpg',
    },
];

export default teasers;
