import React from 'react';
import './Header.scss';
import Logo from '../../assets/img/EntdeckerHaeuschenLogoWhite.svg';
import { Link } from 'react-router-dom';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import FreePlaces from '../FreePlaces/FreePlaces';

const Header = (props) => {
    return (
        <header>
            <div className="inner">
                <div className="header-holder">
                    <Link to="/">
                        <img
                            src={Logo}
                            alt="EntdeckerHäuschen - Großtagespflege in Swisttal Heimerzheim"
                            className="logo"
                        />
                    </Link>
                    <h3>Großtagespflegestelle in Swisttal Heimerzheim</h3>
                    <FreePlaces buttonColor="white" />
                    <Link to="/kontakt" className="button white">
                        Termin vereinbaren
                    </Link>

                    <BurgerMenu />
                </div>
            </div>
        </header>
    );
};

export default Header;
