import React from 'react';
import './PageNavigation.scss';
import teasers from "../../Config/Teasers";
import TeaserList from "../TeaserList/TeaserList";
import {useLocation} from "react-router-dom";

const PageNavigation = () => {
    const location = useLocation();

    return (
        <>
            <div className="pageNavigation">
                <TeaserList teasers={teasers} inNavigation pathname={location.pathname} />
            </div>
        </>
    );
};

export default PageNavigation;
