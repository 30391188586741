import React from 'react';
import './TeaserList.scss';
import Teaser from '../Teaser/Teaser';
import PropTypes from 'prop-types';

const TeaserList = (props) => {
    const { teasers, inNavigation, pathname } = props;

    const pathnameCorrected = pathname ? pathname.replace('/sabine', '').replace('/clarissa', '') : pathname

    return (
        <ul className={`teaserList${inNavigation ? ' inNavigation' : ''}`}>
            {teasers &&
                teasers.map((teaser) => (
                    <li key={teaser.url}>
                        <Teaser teaser={teaser} inNavigation={inNavigation} isActive={teaser.url === pathnameCorrected}/>
                    </li>
                ))
            }
        </ul>
    );
};

TeaserList.propTypes = {
    teasers: PropTypes.array,
    inNavigation: PropTypes.bool,
    pathname: PropTypes.string,
};

export default TeaserList;
